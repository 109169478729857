export const sagaActions = {
    SIGN_IN: 'SIGN_IN',
    SIGN_OUT: 'SIGN_OUT',
    GET_ALL_ORGANIZATIONS: 'GET_ALL_ORGANIZATIONS',
    GET_ALL_ORGANIZATIONS_SUCCESS: 'GET_ALL_ORGANIZATIONS_SUCCESS',
    GET_ALL_ORGANIZATIONS_FAILURE: 'GET_ALL_ORGANIZATIONS_FAILURE',
    GET_ORGANIZATION: 'GET_ORGANIZATION',
    GET_ORGANIZATION_SUCCESS: 'GET_ORGANIZATION_SUCCESS',
    GET_ORGANIZATION_FAILURE: 'GET_ORGANIZATION_FAILURE',
    GET_ORGANIZATION_SETTING: 'GET_ORGANIZATION_SETTING',
    GET_ORGANIZATION_SETTING_SUCCESS: 'GET_ORGANIZATION_SETTING_SUCCESS',
    GET_ORGANIZATION_SETTING_FAILURE: 'GET_ORGANIZATION_SETTING_FAILURE',
    GET_ORGANIZATION_PREFERENCE: 'GET_ORGANIZATION_PREFERENCE',
}