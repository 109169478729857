import { takeLatest, put } from "redux-saga/effects";
import { sagaActions } from "../actions";
import AquinaAPI from "../../../services/apis/aquina-axios";
import { setOrganizationSetting } from "../../organization";

const fetchOrganizationSetting = async (id) => {
  try {
    const response = await AquinaAPI.get("/portal/organization/setting?organizationId=" + id, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

function* getOrganizationSetting(action) {
  const { data } = yield fetchOrganizationSetting(action.id);
  yield put(setOrganizationSetting(data));
}

function* watchGetOrganizationSetting() {
  yield takeLatest(sagaActions.GET_ORGANIZATION_SETTING, getOrganizationSetting);
}

export default watchGetOrganizationSetting;
