// ** Redux Imports
import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialUser = () => {
  return {};
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload;
    },
    handleLogout: (state) => {
      localStorage.removeItem("userData");
      localStorage.removeItem("JWT");
      state.userData = {};
    },
    loginFailed: (state, action) => {
      state.error = action.payload;
    }
  }
});

export const { handleLogin, handleLogout, loginFailed } = authSlice.actions;

export const usernameSelector = createSelector(
  (state) => state.auth.userData,
  (user) => {
    return `${user.FirstName} ${user.LastName}`;
  }
);

export const isAquinaAdmin = createSelector(
  (state) => state.auth.userData,
  (user) => {
    return `${user.FirstName} ${user.LastName}`;
  }
);

export default authSlice.reducer;
