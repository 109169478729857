import axios from "axios"

const aquinaAPI = axios.create({
  baseURL: "https://dev-api.aquinahealth.com/api/v1"
})

aquinaAPI.interceptors.request.use(function (config) {
  const token = localStorage.getItem('JWT');
  config.headers.Authorization =  token ? "Bearer " + token.slice(1, -1) : "";
  return config;
});

export default aquinaAPI;