// ** React Imports
import React, { useEffect, useState } from "react"
// ** Logo
import aquina_icon from "@src/assets/images/icons/aquina_favicon.ico"
import curae_icon from "@src/assets/images/icons/curae_favicon.ico"

const SpinnerComponent = () => {
  const [domain, setDomain] = useState(window.location.hostname.toLowerCase())

  return (
    <div className="fallback-spinner app-loader">
      <div className="loading">
        {domain.includes("curae") ? (
          <>
            <div
              style={{ borderLeft: "3px solid #eeb600" }}
              className="effect-1 effects"
            ></div>
            <div
              style={{ borderLeft: "3px solid #eeb600" }}
              className="effect-2 effects"
            ></div>
            <div
              style={{ borderLeft: "3px solid #eeb600" }}
              className="effect-3 effects"
            ></div>
          </>
        ) : (
          <>
            <div
              style={{ borderLeft: "3px solid #00adee" }}
              className="effect-1 effects"
            ></div>
            <div
              style={{ borderLeft: "3px solid #00adee" }}
              className="effect-2 effects"
            ></div>
            <div
              style={{ borderLeft: "3px solid #00adee" }}
              className="effect-3 effects"
            ></div>
          </>
        )}
      </div>
    </div>
  )
}

export default SpinnerComponent
