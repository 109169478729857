import { takeLatest, put } from "redux-saga/effects";
import { sagaActions } from "../actions";
import { handleLogout } from "../../../redux/authentication";

function* signOut(action) {
  yield put(handleLogout());
  yield action.logoutUser();
  yield action.history.push("/");
}

function* watchSignOut() {
  yield takeLatest(sagaActions.SIGN_OUT, signOut);
}

export default watchSignOut;
