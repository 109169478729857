import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import TRANSLATE_EN from "./Languages/en_language.json"
import TRANSLATE_ES from "./Languages/es_language.json"

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    //debug: true,
    lang: "en",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: TRANSLATE_EN
      },
      es: {
        translation: TRANSLATE_ES
      }
    }
  })

export default i18n
