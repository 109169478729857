import { takeLatest, put } from "redux-saga/effects";
import { sagaActions } from "../actions";
import AquinaAPI from "../../../services/apis/aquina-axios";
import { setOrganizationsList } from "../../organization";

const fetchAllOrganizations = async () => {
  try {
    const response = await AquinaAPI.get("/portal/organization", {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

function* getAllOrganizations(action) {
  const { data } = yield fetchAllOrganizations();
  yield put(setOrganizationsList(data));
}

function* watchGetAllOrganizations() {
  yield takeLatest(sagaActions.GET_ALL_ORGANIZATIONS, getAllOrganizations);
}

export default watchGetAllOrganizations;
