import { put, takeLatest, delay } from "redux-saga/effects";
import { sagaActions } from "../actions";
import { handleLogin, loginFailed } from "../../authentication";
import AquinaAPI from "../../../services/apis/aquina-axios";

const fetchUser = async ({ email, password }) => {
  const response = await AquinaAPI.post(
    "/login",
    JSON.stringify({
      Email: email,
      Password: password,
    }),
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.data;
};

function* saveUser(user, action) {
  localStorage.setItem("userData", JSON.stringify(user));
  localStorage.setItem("JWT", JSON.stringify(user.Token));
  yield put(handleLogin(user));
  yield action.loginUser(user);
  if (action?.history) yield action.history.push("/home");
}

function* signIn(action) {
  const { user, form, setIsLoading, setMessage } = action;
  if (user) {
    yield saveUser(user, action);
  } else {
    try {
      const { data, isError, message } = yield fetchUser({
        email: action.payload.email,
        password: action.payload.password,
      });
      if (isError) {
        form.target.reset();
        setIsLoading(false);
        setMessage(message || "Failed to log you in.");
        yield loginFailed(message || "Failed to log you in.");
        yield delay(3000);
        setMessage(null);
        return;
      } else {
        if (data) yield saveUser(data, action);
      }
    } catch (err) {
      form.target.reset();
      setIsLoading(false);
      yield put(loginFailed(err));
    }
  }
}

function* watchSignIn() {
  yield takeLatest(sagaActions.SIGN_IN, signIn);
}

export default watchSignIn;
