import { all, fork } from "redux-saga/effects";
import signIn from "./signIn";
import signOut from "./signOut";
import getAllOrganizations from "./getAllOrganizations";
import getOrganizationSetting from "./getOrganizationSetting";
import getOrganizationPreference from "./getOrganizationPreference";


export default function* rootSaga() {
  yield all([
    fork(signIn), 
    fork(signOut),
    fork(getAllOrganizations),
    fork(getOrganizationSetting),
    fork(getOrganizationPreference),
  ]);
}
