// ** Redux Imports
import { createSlice, createSelector } from "@reduxjs/toolkit";

const initialOrganization = () => {
  return {};
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    organizationData: initialOrganization(),
    currentOrganization: initialOrganization(),
    list: [],
    modalOpen: false,
  },
  reducers: {
    setOrganizationsList: (state, action) => {
      state.list = action.payload;
    },
    setOrganization: (state, action) => {
      state.organizationData = action.payload;
    },
    setOrganizationSetting: (state, action) => {
      state.organizationData = {
        ...state.organizationData,
        setting: action.payload,
      };
    },
    setCurrentOrganization: (state, action) => {
      state.currentOrganization = action.payload;
    },
    setOrganizationPreference: (state, action) => {
      state.organizationData = {
        ...state.organizationData,
        preference: action.payload,
      };
    },
    modalOpen: (state, action) => {
      state.modalOpen = action.payload;
    },
  },
});

export const {
  setOrganization,
  modalOpen,
  setOrganizationsList,
  setOrganizationPreference,
  setOrganizationSetting,
  setCurrentOrganization,
} = organizationSlice.actions;

export const isModalOpen = createSelector(
  (state) => state.organization.modalOpen,
  (modalState) => modalState
);

export const allOrganizations = createSelector(
  (state) => state.organization.list,
  (orgs) => orgs
);

export const activeOrganizations = createSelector(
  (state) => state.organization.list,
  (orgs) => orgs.filter((org) => org.Active)
);

export const inactiveOrganizations = createSelector(
  (state) => state.organization.list,
  (orgs) => orgs.filter((org) => !org.Active)
);

export const organization = createSelector(
  (state) => state.organization.organizationData,
  (org) => org
);

export const currentOrganization = createSelector(
  (state) => state.organization.currentOrganization,
  (org) => org
);

export const defaultOrganization = createSelector(
  (state) => ({
    id: state.auth.userData.OrganizationId,
    list: state.organization.list,
  }),
  (org) => org?.list?.find((o) => o.OrganizationId === org.id)
);

export default organizationSlice.reducer;
